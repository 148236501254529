import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiRequestService } from '@prekog/pw-common';
import { OrderDetails } from '../order/order.model';
import { GetOrdersRequest } from '../order/get-orders-request.model';
import { GetOrdersResponse } from '../order/get-orders-response.model';
import { Product } from '../order/product.model';
import { GetProductRequest } from '../order/get-product-request';
import { GetProductResponse } from '../order/get-product-response';
import { ConfirmStatusPopupComponent } from '../confirm-status-popup/confirm-status-popup.component';
import { OrderStatus } from '../order/order-status.model';
import { StatusOption } from '../order/statusoption';
import { IsOrderSeparatedRequest } from '../order/isOrderSeparatedRequest';
import { IsOrderSeparatedResponse } from '../order/isOrderSeparatedResponse';

@Component({
    selector: 'app-preorders',
    templateUrl: './preorders.component.html',
    styleUrls: ['./preorders.component.scss']
})
export class PreordersComponent implements OnInit {
    storeBackendUrl = '';

    orderBackendUrl = '';

    orders: OrderDetails[] = [];

    filteredOrder: OrderDetails[] = [];

    preOrderList: OrderDetails[] = [];

    GET_ORDERS_URI = '/admin/get-orders';

    GET_SEPARATED_VALUE_URI = '/admin/isorderseparated';

    GET_ORDEREDPRODUCTS_URI = '/admin/get-orderedproducts';

    index = 0;

    productList: Product[] = [];

    getProductRequest: GetProductRequest = {};

    isProductScreenVisible = false;

    order: OrderDetails = {};

    UPDATE_ORDER_STATUS_URI = '/admin/update-status';

    searchText = '';

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };

    orderStatusOptionList = [
        StatusOption.WAITING_FOR_PAYMENT,
        StatusOption.WAITING_FOR_PROCESSING,
        StatusOption.WAITING_FOR_PUBLICATION,
        StatusOption.PAYMENT_FAILED,
        StatusOption.UNDER_PACKING,
        StatusOption.UNDER_DELIVERY,
        StatusOption.BLOCKED,
        StatusOption.FINISHED,
        StatusOption.DELETED
    ];

    orderStatusStorePickupOptionList = [
        StatusOption.WAITING_FOR_PAYMENT,
        StatusOption.WAITING_FOR_PROCESSING,
        StatusOption.WAITING_FOR_PUBLICATION,
        StatusOption.PAYMENT_FAILED,
        StatusOption.BLOCKED,
        StatusOption.RECEIVABLE,
        StatusOption.FINISHED,
        StatusOption.DELETED
    ];

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('ORDER_BACKEND_URL') private _orderBackendUrl: string,
        @Inject('STORE_BACKEND_URL') private _storeBackendUrl: string,
        private _matDialog: MatDialog,
        private readonly breakpointObserver: BreakpointObserver
    ) {
        this.orderBackendUrl = _orderBackendUrl;
        this.storeBackendUrl = _storeBackendUrl;
    }
    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService
                .sendRequest<GetOrdersRequest, GetOrdersResponse>(this.orderBackendUrl, true, {}, true, this.GET_ORDERS_URI)
                .subscribe((response: GetOrdersResponse) => {
                    this.orders = response.orderList;
                    this.orders.forEach((order, index) => {
                        this.makingPreorderList(order);

                    });
                    this.preOrderList.forEach((preorder,index)=> {

                        preorder.previousStatus = preorder.status;
                        this.orders.push(preorder);
                        if (preorder.taxNumber) {
                            this.index = index;
                        }

                        const date = preorder.insertedTimestamp?.toString();
                        const splittedDate = date?.split(',');
                        preorder.insertedTimestampp = splittedDate![0] + '.' + splittedDate![1] + '.' + splittedDate![2];

                    })
                    this.filteredOrder = this.preOrderList;
                });
        } else if (!sessionStorage.getItem('token')) {
            void this._router.navigateByUrl('/');
        }
    }

    makingPreorderList(order: OrderDetails) {
        order.orderProductList!.forEach((orderProduct) => {
            if (orderProduct.isPreorder) {
                this.preOrderList.push(order);
            }
        });
        this.preOrderList.forEach((order)=> {
            let isOrderSeparatedRequest:IsOrderSeparatedRequest = {
                customerOrderReference:order.customerOrderReference

            }
            this._apiRequestService
            .sendRequest<IsOrderSeparatedRequest, IsOrderSeparatedResponse>(this.orderBackendUrl, true, isOrderSeparatedRequest, true, this.GET_SEPARATED_VALUE_URI)
            .subscribe((response: IsOrderSeparatedResponse) => {
                order.separated = response.separated!;
            });

        })
    }

    getProducts(order: OrderDetails) {
        let idList: Array<number> = [];
        order.orderProductList!.forEach((orderProduct) => {
            idList.push(orderProduct.productId!);
        });

        this.getProductRequest = {
            productIdList: idList
        };

        this._apiRequestService
            .sendRequest<GetProductRequest, GetProductResponse>(
                this.storeBackendUrl,
                true,
                this.getProductRequest,
                true,
                this.GET_ORDEREDPRODUCTS_URI
            )
            .subscribe((response: GetProductResponse) => {
                const productResponse = response as GetProductResponse;

                this.productList = productResponse.storeProducts as Product[];
                order.orderProductList!.forEach((orderProduct, index1) => {
                    this.productList.forEach((product, index2) => {
                        if (index1 === index2) {
                            if (orderProduct.productId === product.id) {
                                //we need it for the orders before which doesn't have price variable
                                if (orderProduct.price !== 0) {
                                    product.originalPrice = orderProduct.price;
                                } else if (product.webshopPriceActive) {
                                    product.originalPrice = product.webshopPrice;
                                } else if (product.discountPriceActive) {
                                    product.originalPrice = product.discountPrice;
                                }
                                product.packageName = orderProduct.packageName;
                                product.quantity = orderProduct.quantity;
                            }
                        }
                    });
                });
            });

        this.order = order;
        this.isProductScreenVisible = true;
    }

    updateOrderStatus(order: OrderDetails) {
        const updateOrderStatusRequest: OrderStatus = new OrderStatus();

        updateOrderStatusRequest.orderDetailReference = order.orderDetailReference;
        updateOrderStatusRequest.status = order.status;

        this._apiRequestService
            .sendRequest<OrderStatus, GetOrdersResponse>(
                this.orderBackendUrl,
                true,
                updateOrderStatusRequest,
                true,
                this.UPDATE_ORDER_STATUS_URI
            )
            .subscribe(() => {
                window.alert((updateOrderStatusRequest.orderDetailReference as string) + ' ' + 'order updated!');
                //after setting DELETED status refresh the window content
                if (order.status === 'DELETED') {
                    window.location.reload();
                }
            });
    }

    loadConfirmOrderStatusPopup(order: OrderDetails, enterAnimationDuration: string, exitAnimationDuration: string) {
        const isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
        this.dialogConfiger.data = order;
        const popupDialog = this._matDialog.open(ConfirmStatusPopupComponent, {
            data: order,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw'
        });
        const smallDialogSubscription = isExtraSmall.subscribe((size) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '70vh');
            } else {
                popupDialog.updateSize('calc(50% - 50px)', '');
            }
        });
        popupDialog.afterClosed().subscribe(() => {
            smallDialogSubscription.unsubscribe();
        });
    }

    public onClick(orderReference: string): void {
        this._router.navigateByUrl('order#' + orderReference);
        this.isProductScreenVisible = false;
    }
}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { GetPackageRequest } from './getpackagerequest';
import { Package } from '../package';
import { GetPackageResponse } from './getpackageresponse';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService, HttpError } from '@prekog/pw-common';
import { UploadFileResponse } from '../../products/addproduct/uploadfileresponse';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdatePackageRequest } from './updatepackagerequest';
import { UpdatePackagResponse } from './updatepackageresponse';
import { UploadToAnExistingFolderRequest } from '../../products/uploadtoanexistingfolderrequest';
import { UploadToAnExistingFolderResponse } from '../../products/uploadtoanexistingfolderresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { GetProductsForPackageRequest } from '../updatepackageproducts/getproductsForPackageRequest';
import { GetProductsResponse } from '../../products/getproductsresponse';
import { GetProductsForPackageResponse } from '../updatepackageproducts/getproductsforpackageresponse';
import { CreatePackageProductRequest } from '../addproductstopackage/createpackageproductrequest';
import { CreatePackageProductResponse } from '../addproductstopackage/createpackageproductresponse';
import { PackageProduct } from '../addproductstopackage/packageproduct';
import { DeletePackageProductRequest } from '../updatepackageproducts/deletepackageproductrequest';
import { DeletePackageProductResponse } from '../updatepackageproducts/deletepackageproductresponse';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AddpackagepricepopupComponent } from '../addpackage/addpackagepricepopup/addpackagepricepopup.component';
import { Material } from '../../products/material';

@Component({
    selector: 'app-updatepackage',
    templateUrl: './updatepackage.component.html',
    styleUrls: ['./updatepackage.component.scss']
})
export class UpdatepackageComponent implements OnInit {
    getPackageRequest: GetPackageRequest = {};

    storeBaseUrl = '';

    getPackageUrl = '/admin/getpackage';

    package: Package = {};

    @ViewChild('fileCoverInput') fileCoverInput!: ElementRef;
    fileCover: File | null = null;

    uploadFileUrl = '/admin/uploadFile';

    uploadFileToAnExistingFolderUrl = '/admin/updatefile';

    updatePackageUrl = '/admin/updatepackage';

    fileBaseUrl = '';

    uploadFileResponse: UploadFileResponse = {};

    downloadUri = '';

    url = '';

    packageForm?: FormGroup;

    updatePackageRequest: UpdatePackageRequest = {};

    uploadFileToAnExistingFolderRequest: UploadToAnExistingFolderRequest = {};

    uploadFileToAnExistingFolderResponse: UploadToAnExistingFolderResponse = {};

    updateCoverFile = false;

    getProductsForPackageRequest: GetProductsForPackageRequest = {};

    updateableProducts: Material[] = [];

    products: Material[] = [];

    storeExistingProductsURI = '/store/get-products';


    createPackageProductUrl = '/admin/createpackageproduct';

    deletePackageProductUrl = '/admin/deletepackageproduct';

    getProductsForPackageUrl = '/admin/get-packageproduct';

    filteredBooks: Material[] = [];

    createPackageProductRequest: CreatePackageProductRequest = {};

    deletePackageProductRequest: DeletePackageProductRequest = {};

    deletePackageProductResponse: DeletePackageProductResponse = {};

    maxFileSize = 5000000;

    isExtraSmall: Observable<BreakpointState>;
    isSmall: Observable<BreakpointState>;
    isMedium: Observable<BreakpointState>;

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };
    enterAnimationDuration = '500ms';
    exitAnimationDuration = '200ms';

    sum = 0;

    searchText = '';

    constructor(
        private _router: Router,
        private _fb: FormBuilder,
        private _toastMessageService: ToastmessageService,
        @Inject('FILE_MANAGER_URL') private _fileManagerUrl: string,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _translateService: TranslationService,
        private readonly breakpointObserver: BreakpointObserver,
        private _matDialog: MatDialog
    ) {
        this.storeBaseUrl = _storeBaseUrl;
        this.fileBaseUrl = _fileManagerUrl;
        this.createPPackageForm();
        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
        this.isSmall = this.breakpointObserver.observe(Breakpoints.Small);
        this.isMedium = this.breakpointObserver.observe(Breakpoints.Medium);
    }

    addProductToPackage(product: Material) {
        this.sum += product.packagePrice!;
        if (!product.packagePrice) {
            this.openAddPackegPricePopup(product);
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('Kérem adja meg a csomagárat a ' + product.author + ':' + product.title + ' könyvhöz!'),
                MAT_ICON.error
            );
            this.dismissErrorFiveSeconds();
        }
        else {
            const index = this.products.indexOf(product);
            this.products.splice(index, 1);
            this.updateableProducts.push(product);
        }
    }

    openAddPackegPricePopup(product: Material) {
        const popupDialog = this._matDialog.open(AddpackagepricepopupComponent, {
            data: product,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }
    deleteFromPackageProductList(product: Material) {
        this.sum -= product.packagePrice!;
        const index = this.updateableProducts.indexOf(product);
        this.updateableProducts.splice(index, 1);
        this.products.push(product);
    }


    //TODO combine it with Products changing option!
    ngOnInit(): void {
        const lastSegmentOfUrl = window.location.href.split('/').pop();
        const routerLink = lastSegmentOfUrl as string;
        this.getPackageRequest = {
            routerLink: routerLink
        };

        this._apiRequestService
            .sendRequest<GetPackageRequest, GetPackageResponse>(this.storeBaseUrl, true, this.getPackageRequest, true, this.getPackageUrl)
            .subscribe((response) => {
                this.package = response.packageObject!;
                this.getProductsForPackageRequest = {
                    routerLink: this.package.routerLink
                };
                this._apiRequestService
                    .sendRequest<GetProductsForPackageRequest, GetProductsForPackageResponse>(
                        this.storeBaseUrl,
                        true,
                        this.getProductsForPackageRequest,
                        true,
                        this.getProductsForPackageUrl
                    )
                    .pipe(
                        catchError((error: HttpErrorResponse) => {
                            return throwError(() => {
                                const httpError: HttpError = error.error as HttpError;

                                this._toastMessageService.displayToastMessage(
                                    TOAST_STATE.error,
                                    this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                    MAT_ICON.error
                                );
                                this.dismissError();

                                this._router.navigateByUrl('/');
                            });
                        })
                    )
                    .subscribe((response) => {
                        this.updateableProducts = response.products as Material[];
                        this._apiRequestService
                            .sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI)
                            .pipe(
                                catchError((error: HttpErrorResponse) => {
                                    return throwError(() => {
                                        const httpError: HttpError = error.error as HttpError;

                                        this._toastMessageService.displayToastMessage(
                                            TOAST_STATE.error,
                                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                            MAT_ICON.error
                                        );
                                        this.dismissError();

                                        this._router.navigateByUrl('/');
                                    });
                                })
                            )
                            .subscribe((response) => {
                                const products = response as GetProductsResponse;
                                products.productList as Material[];

                                //skipping same elements with updateAbleProducts from products
                                this.products = products.productList?.filter(p => !this.updateableProducts.some(p2 => p2.routerLink === p.routerLink)) as Material[];

                                this.filteredBooks = this.products;
                                if (sessionStorage.getItem('product')) {
                                    const product = JSON.parse(sessionStorage.getItem('product') as string) as Material;
                                    const index = this.products.indexOf(product);
                                    this.products.splice(index, 1);
                                    this.updateableProducts.push(product);
                                    sessionStorage.removeItem('product');

                                }
                                this.updateableProducts.forEach((updatableProduct) => {
                                    this.sum += updatableProduct.packagePrice!;
                                })
                            });
                    });
            });
    }



    onChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileCover = target.files![0];
        if (this.fileCover.name.split('.').pop() === 'png') {
            this.updateCoverFile = true;
        }
        else {
            this.updateCoverFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".png" kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }

    }

    updateFile(packagee: Package, downloadUrl: string) {

        const folderName = downloadUrl.split('/').pop() as string;

        this._apiRequestService.updateFile(this.fileCover!, folderName, true, true, this.fileBaseUrl, this.uploadFileToAnExistingFolderUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage), MAT_ICON.error);
                        this.dismissError();


                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                this.uploadFileResponse = response as UploadFileResponse;
                this.downloadUri = this.uploadFileResponse.downloadUri as string;
                if (this.uploadFileResponse.downloadUri) {
                    this._toastMessageService.displayToastMessage(
                        TOAST_STATE.success,
                        'Sikeres fájl feltöltés ' + this.uploadFileResponse.fileName!,
                        MAT_ICON.success
                    );
                    this.dismissError();
                    this.url = this.downloadUri;

                    this.updateCoverFile = false;
                }

                this.collectPackageData(packagee);
                this.updatePackageRequest = {
                    packageObject: packagee
                }
                this._apiRequestService
                    .sendRequest<UpdatePackageRequest, UpdatePackagResponse>(this.storeBaseUrl, true, this.updatePackageRequest, true, this.updatePackageUrl)
                    .pipe(
                        catchError((error: HttpErrorResponse) => {
                            return throwError(() => {
                                const httpError: HttpError = error.error as HttpError;

                                this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage), MAT_ICON.error);
                                this.dismissError();

                                this._router.navigateByUrl('/');
                            });
                        })
                    )
                    .subscribe((response) => {
                        this.updatePackageProducts();

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.success,
                            response.message as string,
                            MAT_ICON.success
                        );
                        this.dismissError();
                    });

            });


    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
    private dismissErrorFiveSeconds(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 5000);
    }
    createPPackageForm() {
        this.packageForm = this._fb.group({
            packageName: ['', [Validators.required]]

        });
    }

    collectPackageData(packagee: Package) {
        packagee = {
            packageName: this.packageForm?.controls['packageName'].value as string | undefined
        };

        if (this.url) {
            this.package.imageUrl = this.url;
        }
    }

    updatePackage(packagee: Package) {
        if (this.fileCover?.size! > this.maxFileSize) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                MAT_ICON.error
            );
            this.dismissError();
            return;
        }
        if (this.fileCover !== null && this.fileCover.size < this.maxFileSize) {
            this.updateFile(packagee, packagee.imageUrl!);
        }
        if (this.fileCover === null) {
            this.collectPackageData(packagee);
            this.updatePackageRequest = {
                packageObject: packagee
            }
            this._apiRequestService
                .sendRequest<UpdatePackageRequest, UpdatePackagResponse>(this.storeBaseUrl, true, this.updatePackageRequest, true, this.updatePackageUrl)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage), MAT_ICON.error);
                            this.dismissError();

                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    this.updatePackageProducts();

                    this._toastMessageService.displayToastMessage(
                        TOAST_STATE.success,
                        response.message as string,
                        MAT_ICON.success
                    );
                    this.dismissError();
                });
        }




    }


    clearSelectedCoverFile() {
        this.fileCoverInput.nativeElement.value = null;
        this.updateCoverFile = false;
    }

    updatePackageProducts() {
        //TODO separate backend calls to different methods for clean code
        this.deletePackageProductRequest = {
            packageId: this.package.id
        };
        this._apiRequestService
            .sendRequest<DeletePackageProductRequest, DeletePackageProductResponse>(
                this.storeBaseUrl,
                true,
                this.deletePackageProductRequest,
                true,
                this.deletePackageProductUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((responseMessage) => {
                let packageProductList: PackageProduct[] = [];
                this.updateableProducts.forEach((productsToPackage) => {
                    const packageProduct: PackageProduct = new PackageProduct();
                    packageProduct.productId = productsToPackage.id;
                    packageProduct.packageObject = this.package;
                    packageProductList.push(packageProduct);
                });
                this.createPackageProductRequest = {
                    packageProduct: packageProductList
                };
                this._apiRequestService
                    .sendRequest<CreatePackageProductRequest, CreatePackageProductResponse>(
                        this.storeBaseUrl,
                        true,
                        this.createPackageProductRequest,
                        true,
                        this.createPackageProductUrl
                    )
                    .pipe(
                        catchError((error: HttpErrorResponse) => {
                            return throwError(() => {
                                const httpError: HttpError = error.error as HttpError;

                                this._toastMessageService.displayToastMessage(
                                    TOAST_STATE.error,
                                    this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                    MAT_ICON.error
                                );
                                this.dismissError();

                                this._router.navigateByUrl('/');
                            });
                        })
                    )
                    .subscribe((responseMessage) => {
                        if (this.fileCover === null || this.fileCover.size < this.maxFileSize) {
                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.success,
                                responseMessage.responseMessage!,
                                MAT_ICON.success
                            );
                            this.dismissError();
                        }
                    });
            });
    }
}

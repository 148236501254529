<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/onsaleperiod">arrow_back</mat-icon>
    </div>
    <div>
        <h3>ÚJ AKCIÓ HOZZÁADÁSA</h3>
    </div>
    <div class="contact-form">
        <form [formGroup]="saleForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <span>Akció neve</span>
                    <div class="data">
                        <input
                            [(ngModel)]="onSaleName"
                            formControlName="onSaleName"
                            id="onSaleName"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Akció kezdő dátuma</span>
                    <div class="data">
                        <input formControlName="onSaleStartDate" id="onSaleStartDate" class="form-control" type="date" required />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Akció Befejező dátuma</span>
                    <div class="data">
                        <input formControlName="onSaleEndDate" id="onSaleEndDate" class="form-control" type="date" required />
                    </div>
                </div>
            </div>
        </form>
    </div>
    <h3>KÖNYVEK HOZZÁADÁSA</h3>
    <div class="drag-and-drop-container">
        <div class="search-main-container">
            <div class="search-bar">
                <div class="search-tool">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                    <input [(ngModel)]="searchText" type="search" placeholder="Szerző, cím neve" />
                </div>
            </div>
            <div class="title">
                <h3 *ngIf="!onSaleName">Új akcióhoz tartozó könyvek</h3>
                <h3 *ngIf="onSaleName">{{ onSaleName }} akcióhoz tartozó könyvek</h3>
            </div>
        </div>
        <div class="drag-and-drop">
            <div class="products-container">
                <div class="products">
                    <div *ngFor="let product of products | filter : searchText" class="product">
                        {{ product.author + ': ' + product.title }}
                        <mat-icon (click)="addSaleProduct(product)">add_circle_outline</mat-icon>
                    </div>
                </div>
            </div>
            <div class="title-mobile">
                <h3 *ngIf="!onSaleName">Új akcióhoz tartozó könyvek</h3>
                <h3 *ngIf="onSaleName">{{ onSaleName }} akcióhoz tartozó könyvek</h3>
            </div>
            <div class="products-container">
                <div class="products">
                    <div *ngFor="let product of productsToSale" class="product">
                        {{ product.author + ': ' + product.title }}
                        <mat-icon (click)="deleteFromSale(product)">remove_circle_outline</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="addOnSalePeriod()"
                    [ngStyle]="{ background: '#e8fcfd', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!onSaleName"
                >
                    AKCIÓ MENTÉSE
                </button>
            </div>
        </div>
    </div>
</div>

<div class="packages">
    <div class="title">
        <div class="tit">
            <h3>CSOMAGOK</h3>
            <div class="add-icon">
                <mat-icon routerLink="/addpackage">add_circle</mat-icon>
            </div>
        </div>
    </div>
    <div class="add-new-package">
        <div class="search-bar">
            <div class="search-tool">
                <input [(ngModel)]="searchText" type="search" placeholder="Csomag neve" />
                <div class="icon">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div *ngFor="let package of packages | filter : searchText; let i = index" class="package">
        <div class="pro">
            <div class="name">
                <div
                    [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
                    class="button"
                    routerLink="{{ package.routerLink }}"
                >
                    {{ package.packageName }}
                </div>
                <div [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }" class="delete-icon">
                    <mat-icon [ngStyle]="{ cursor: 'pointer' }" (click)="openDeletePackagePopup(package)">delete_forever</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>

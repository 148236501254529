import { Component, Inject, OnInit } from '@angular/core';
import { Serie } from './serie';
import { SeriesResponse } from './seriesresponse';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'app-series',
    templateUrl: './series.component.html',
    styleUrls: ['./series.component.scss']
})
export class SeriesComponent implements OnInit {
    storeBaseUrl = '';

    getSeriesUrl = '/admin/get-series';

    serieList: Serie[] = [];

    seriesResponse: SeriesResponse = {};

    filteredSeries: Serie[] = [];

    searchText = '';

    constructor(
        private _router: Router,
        private _toastMessageService: ToastmessageService,
        private _translateService: TranslationService,
        @Inject('STORE_BACKEND_URL') private _storeBeUrl: string,
        private _apiRequestService: ApiRequestService
    ) {
        this.storeBaseUrl = _storeBeUrl;
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService
                .sendRequest<{}, SeriesResponse>(this.storeBaseUrl, true, {}, true, this.getSeriesUrl)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();

                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    this.serieList = response.serieList!;
                    this.filteredSeries = this.serieList;
                });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { OrderWithDate } from './orderwithdate';
import { GetOrdersResponse } from '../get-orders-response.model';
import { MatDialogConfig } from '@angular/material/dialog';
import { FindOrderResponse } from './findorderresponse';
import { FindOrderRequest } from './findorderrequest';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-getorders',
    templateUrl: './getorders.component.html',
    styleUrls: ['./getorders.component.scss']
})
export class GetordersComponent implements OnInit {
    orderBackendUrl = '';

    dateForm?: FormGroup;

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };

    filteredOrder: OrderWithDate[] = [];

    orders: OrderWithDate[] = [];

    findOrderByOrderReferenceUrl = '/admin/find-order-by-orderreference';

    getOrdersUrl = '/admin/get-orderswith';

    @ViewChild('startDateElementInput') startDateElementInput!: ElementRef;
    startDateElement = '';

    @ViewChild('endDateElementInput') endDateElementInput!: ElementRef;
    endDateElement = '';

    disableDate = false;

    isSearchAboutOrderReference = true;

    isSearchActive = false;

    selected='Vissza az előző heti rendelésekhez';

    searchText = '';

    constructor(
        private _fb: FormBuilder,
        private _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('ORDER_BACKEND_URL') private _orderBackendUrl: string,
        private _toastMessageService: ToastmessageService
    ) {
        this.orderBackendUrl = _orderBackendUrl;

    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            if (sessionStorage.getItem('filteredOrder')) {
                this.filteredOrder = JSON.parse(sessionStorage.getItem('filteredOrder') as string) as OrderWithDate[];
                sessionStorage.removeItem('filteredOrder');
            }
            else {
                this._apiRequestService
                    .sendRequest<{}, GetOrdersResponse>(this.orderBackendUrl, true, {}, true, this.getOrdersUrl)
                    .subscribe((response) => {
                        this.orders = response.orderList as OrderWithDate[];
                        console.log(this.orders);

                        this.filteredOrder = this.orders;
                    });
            }
        }

        else {
            this._router.navigateByUrl('/');
        }

    }

    findOrderBy() {
        if (!this.isSearchAboutOrderReference) {
            this.collectDates();

            if (this.startDateElement === '' || this.endDateElement === '') {
                this._toastMessageService.displayToastMessage(
                    TOAST_STATE.warning,
                    "Kérem adjon meg kezdő és befejező dátumot!",
                    MAT_ICON.warning
                );
                this.dismissError();
            }
            else {
                this.isSearchActive = true;
                this.filteredOrder = [];
                this.orders = [];
                this.findOrder();
            }
        }
        else {
            if (this.searchText === '') {
                this._toastMessageService.displayToastMessage(
                    TOAST_STATE.warning,
                    "Kérem adja meg a rendelési azonosítót!",
                    MAT_ICON.warning
                );
                this.dismissError();
            }
            else {
                this.isSearchActive = true;
                this.filteredOrder = [];
                this.orders = [];
                this.findOrder();
            }
        }



    }

    findOrder() {
        const findOrderRequest = {
            searchPiece: this.searchText,
            startDate: this.startDateElement,
            endDate: this.endDateElement,
            isSearchAboutOrderReference: this.isSearchAboutOrderReference

        }

        this._apiRequestService
            .sendRequest<FindOrderRequest, FindOrderResponse>(this.orderBackendUrl, true, findOrderRequest, true, this.findOrderByOrderReferenceUrl)
            .subscribe((response) => {
                const orderWithInsertedTimeStamp = response.orderWithDate as OrderWithDate[];
                if (orderWithInsertedTimeStamp !== null) {
                    //this.filteredOrder.push(orderWithInsertedTimeStamp);
                    this.orders = [...orderWithInsertedTimeStamp];
                }
                else {
                    this._toastMessageService.displayToastMessage(
                        TOAST_STATE.success,
                        response.responseMessage!,
                        MAT_ICON.success
                    );
                    this.dismissError();

                }
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }


    collectDates() {
        let startDate = this.startDateElement;
        let endDate = this.endDateElement;
        console.log(endDate)
        this.startDateElement = startDate?.replace(/\D/g, '-') as string;
        this.endDateElement = endDate?.replace(/\D/g, '-') as string;

    }

    onChangeListfilter() {
        if (this.searchText !== '') {
            this.disableDate = true;
            console.log(this.disableDate)
        }
        else {
            this.disableDate = false;
            console.log(this.disableDate)
        }
    }

    searchAboutOnChangeOnDates() {
        this.isSearchAboutOrderReference = false;
        this.searchText = '';
    }

    searchAboutOnChangeOnOrderReference() {
        this.isSearchAboutOrderReference = true;
        this.startDateElementInput.nativeElement.value = '';
        this.endDateElementInput.nativeElement.value = '';
        this.startDateElement = '';
        this.endDateElement = '';

    }

    reload() {
        window.location.reload();
    }

    findOrderDetailsByOrderId(orderId: number) {
        this._router.navigateByUrl('/getorders/' + String(orderId!))
        if (this.isSearchActive) {
            sessionStorage.setItem('filteredOrder', JSON.stringify(this.filteredOrder));
        }

    }
}

<div class="search-bar-container">
    <div class="search-bar">
        <input [(ngModel)]="searchText" type="search" placeholder="Email, telefonszám, cégnév, név..." />
    </div>
</div>
<div class="send-news-letter-container">
    <div class="send-news-letter">
        <button>Hírlevél küldése</button>
    </div>
</div>
<div class="registered-customers-container">
    <div
        [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
        class="registered-customer"
        *ngFor="let client of personalData | filter : searchText; let i = index"
    >
        <div class="field-container">
            <div id="{{ client.email }}" class="field">
                <span>Email</span>
                <div class="data">
                    <span>{{ client.email }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="client.isCompany" class="field-container">
            <div id="{{ client.email }}" class="field">
                <span>Cégnév</span>
                <div class="data">
                    <span>{{ client.companyName }}</span>
                </div>
            </div>
        </div>
        <div *ngIf="client.isCompany" class="field-container">
            <div class="field">
                <span>Adószám</span>
                <div class="data">
                    <span> {{ client.taxNumber }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Vezetéknév</span>
                <div class="data">
                    <span> {{ client.lastName }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Keresztnév</span>
                <div class="data">
                    <span> {{ client.firstName }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Telefonszám</span>
                <div class="data">
                    <span> {{ client.phoneNumber }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Cím</span>
                <div class="data">
                    <span> {{ client.zipCode }} {{ client.settlement }} {{ client.address }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Hírlevél</span>
                <div class="data">
                    <span> {{ client.subscribedForNewsLetter }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { OnSalePeriod } from './addproductsonsale/onsaleperiod';
import { FormBuilder } from '@angular/forms';
import { ApiRequestService, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { GetOnSalePeriodResponse } from './getonsaleperiodresponse';

@Component({
    selector: 'app-getexistingonsaleperiods',
    templateUrl: './onsaleperiods.component.html',
    styleUrls: ['./onsaleperiods.component.scss']
})
export class OnsaleperiodsComponent implements OnInit {
    onSalePeriod?: OnSalePeriod[] = [];

    storeBaseUrl = '';

    getOnSalePeriodsUrl = '/admin/get-on-sale-periods';

    filteredOnSalePeriods: OnSalePeriod[] = [];

    searchText = '';

    constructor(
        private _router: Router,
        private _fb: FormBuilder,
        private _toastMessageService: ToastmessageService,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _translateService: TranslationService
    ) {
        this.storeBaseUrl = _storeBaseUrl;
    }
    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService
                .sendRequest<{}, GetOnSalePeriodResponse>(this.storeBaseUrl, true, {}, true, this.getOnSalePeriodsUrl)
                .subscribe((response) => {
                    this.onSalePeriod = response.onSalePeriodList as OnSalePeriod[];
                    this.filteredOnSalePeriods = this.onSalePeriod!;
                });
        } else {
            this._router.navigateByUrl('/');
        }
    }

}

import { Component, Inject, OnInit } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService,HttpError} from '@prekog/pw-common';
import { Package } from '../package';
import { RemovePackageRequest } from './removepackagerequest';
import { RemovePackageResponse } from './removepackageresponse';

@Component({
  selector: 'app-removepackagepopup',
  templateUrl: './removepackagepopup.component.html',
  styleUrls: ['./removepackagepopup.component.scss']
})
export class RemovepackagepopupComponent {
    deleteBannerUrl = '/admin/delete-banner';

    storeBaseUrl = '';

    selectedAlign: 'start' | 'center' | 'end';

    removePackageUrl = '/admin/remove-package';

    constructor(
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _matdialog:MatDialog,
        @Inject(MAT_DIALOG_DATA) public _package: Package
    ) {
        this.storeBaseUrl = _storeBaseUrl;

        this.selectedAlign = 'end';
    }

      //TODO confirm popup!!!
      deletePackage() {
        const removePackageRequest: RemovePackageRequest = {
            packageId: this._package.id
        };

        this._apiRequestService
            .sendRequest<RemovePackageRequest, RemovePackageResponse>(
                this.storeBaseUrl,
                true,
                removePackageRequest,
                true,
                this.removePackageUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            'Sikertelen törlés' + httpError.errorMessage,
                            MAT_ICON.error
                        );
                        this.dismissError();
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this.closeDialog();
                window.location.reload();
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
    closeDialog() {
        this._matdialog.closeAll();
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { GetProductsResponse } from '../../products/getproductsresponse';
import { AddOnSalePeriodRequest } from './addonsaleperiodrequest';
import { OnSalePeriod } from './onsaleperiod';
import { ProductsOnSale } from './productsonsale';
import { AddProductsOnSaleRequest } from './addproductsonsalerequest';
import { AddProductsOnSaleResponse } from './addproductsonsaleresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AddOnSaleperiodResponse } from './addonsaleperiodresponse';
import { GetNotOnSaleableProductsResponse } from './getnotonsaleableproductsresponse';
import { AddDiscountpricepopupComponent } from './adddiscountpricepopup/adddiscountpricepopup.component';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Material } from '../../products/material';
@Component({
    selector: 'app-productsale',
    templateUrl: './addproductsonsale.component.html',
    styleUrls: ['./addproductsonsale.component.scss']
})
export class ProductsaleComponent implements OnInit {
    storeBaseUrl = '';

    products: Material[] = [];

    storeExistingProductsURI = '/store/get-products';

    productsToSale: Material[] = [];

    saleForm?: FormGroup;

    addOnSalePeriodUrl = '/admin/add-on-sale-period';

    addProductsOnSaleUrl = '/admin/add-products-on-sale';

    getNotOnSaleableProductsUrl = '/admin/get-not-onsaleable-products';

    onSalePeriod: OnSalePeriod = {};

    onSaleName = '';

    productSaleRequest: AddOnSalePeriodRequest = {};

    addProductsOnSaleRequest: AddProductsOnSaleRequest = {};

    notOnSaleAbleProducts: Material[] = [];

    isExtraSmall: Observable<BreakpointState>;
    isSmall: Observable<BreakpointState>;
    isMedium: Observable<BreakpointState>;

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };
    enterAnimationDuration = '500ms';
    exitAnimationDuration = '200ms';
    filteredBooks: Material[] = [];

    searchText = '';

    constructor(
        public _router: Router,
        private _fb: FormBuilder,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _toastMessageService: ToastmessageService,
        private _translateService: TranslationService,
        private readonly breakpointObserver: BreakpointObserver,
        private _matDialog: MatDialog
    ) {
        this.storeBaseUrl = _storeBaseUrl;
        this.createSaleForm();
        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
        this.isSmall = this.breakpointObserver.observe(Breakpoints.Small);
        this.isMedium = this.breakpointObserver.observe(Breakpoints.Medium);
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        if(sessionStorage.getItem('product')) {
            const product=JSON.parse(sessionStorage.getItem('product') as string) as Material;
            const index = this.products.indexOf(product);
            this.products.splice(index, 1);
            this.productsToSale.push(product);
            sessionStorage.removeItem('product');

        }
        this._apiRequestService.sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI).subscribe((response) => {
            const products = response as GetProductsResponse;
            this.products = products.productList as Material[];

            this._apiRequestService
                .sendRequest<{}, GetNotOnSaleableProductsResponse>(this.storeBaseUrl, true, {}, true, this.getNotOnSaleableProductsUrl)
                .subscribe((response: GetNotOnSaleableProductsResponse) => {
                    this.notOnSaleAbleProducts = response.notOnSaleableProductList as Material[];
                    console.log(this.notOnSaleAbleProducts);
                    //skipping notOnSaleableProducts from products
                    this.products = this.products.filter(
                        (p) => !this.notOnSaleAbleProducts.some((p2) => p2.routerLink === p.routerLink)
                    ) as Material[];

                    this.products.forEach((product) => {
                        if (!product.isAvailable) {
                            const index = this.products.indexOf(product);
                            this.products.splice(index, 1);
                        }
                    });
                    this.filteredBooks = this.products;
                    console.log(this.filteredBooks);
                });
        });
    }

    getNotOnSaleableProducts() {}

    //TODO backend call with confirm popup
    deleteFromSale(product: Material) {
        const index = this.productsToSale.indexOf(product);
        this.productsToSale.splice(index, 1);
        this.products.push(product);
    }

    addSaleProduct(product: Material) {
        if (!product.discountPrice) {
            this.openDiscountPricePopup(product);
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('Kérem adja meg az akciós árát a ' + product.author + ':' + product.title + ' könyvhöz!'),
                MAT_ICON.error
            );
            this.dismissErrorFiveSeconds();
        } else {
            const index = this.products.indexOf(product);
            this.products.splice(index, 1);
            this.productsToSale.push(product);
        }
    }
    openDiscountPricePopup(product: Material) {
        const popupDialog = this._matDialog.open(AddDiscountpricepopupComponent, {
            data: product,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }
    createSaleForm() {
        this.saleForm = this._fb.group({
            onSaleEndDate: ['', [Validators.required]],
            onSaleStartDate: ['', [Validators.required]],
            onSaleName: ['', [Validators.required]]
        });
    }

    collectProductsToSaleData() {
        this.onSalePeriod = {
            name: this.saleForm?.controls['onSaleName'].value as string | undefined,
            startDate: this.saleForm?.controls['onSaleStartDate'].value as string | undefined,
            endDate: this.saleForm?.controls['onSaleEndDate'].value as string | undefined
        };
    }

    removeProductFromDiscountedProducts(id: number) {}

    // TODO separate into 2 usecases, 1) save package, 2) save to this package
    addOnSalePeriod() {
        this.collectProductsToSaleData();

        this.productsToSale.forEach((product) => {
            this.productSaleRequest = {
                onSalePeriod: this.onSalePeriod
            };

            this._apiRequestService
                .sendRequest<AddOnSalePeriodRequest, AddOnSaleperiodResponse>(
                    this.storeBaseUrl,
                    true,
                    this.productSaleRequest,
                    true,
                    this.addOnSalePeriodUrl
                )
                .subscribe((response) => {
                    this.onSalePeriod = response.onSalePeriod as OnSalePeriod;
                    this.addProducts();
                });
        });
    }

    addProducts() {
        let productsOnSale: ProductsOnSale[] = [];
        this.productsToSale.forEach((productsToSale) => {
            const productsSale: ProductsOnSale = new ProductsOnSale();
            productsSale.productId = productsToSale.id;
            productsSale.onSalePeriod = this.onSalePeriod;
            productsOnSale.push(productsSale);
        });

        this.addProductsOnSaleRequest = {
            productOnSaleList: productsOnSale
        };
        this._apiRequestService
            .sendRequest<AddProductsOnSaleRequest, AddProductsOnSaleResponse>(
                this.storeBaseUrl,
                true,
                this.addProductsOnSaleRequest,
                true,
                this.addProductsOnSaleUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((responseMessage) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, responseMessage.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this._router.navigateByUrl('/onsaleperiod');
            });
    }

    private dismissErrorFiveSeconds(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 5000);
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
}

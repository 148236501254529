import { Component, Inject, OnInit } from '@angular/core';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { Serie } from '../serie';
import { GetSerieResponse } from './getserieresponse';
import { GetSerieRequest } from './getserierequest';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdateSerieRequest } from './updateserierequest';
import { UpdateSerieResponse } from './updateserieresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BookWithSerienumber } from '../addserie/bookwithserienumber';
import { AddProductToSerieRequest } from '../addproductstoserie/addproducttoserierequest';
import { AddProductToSerieResponse } from '../addproductstoserie/addproducttoserieresponse';
import { GetProductsResponse } from '../../products/getproductsresponse';
import { DeleteSerieProductRequest } from './deleteserieproductrequest';
import { DeleteSerieProductResponse } from './deleteserieproductresponse';
import { Material } from '../../products/material';

@Component({
    selector: 'app-updateserie',
    templateUrl: './updateserie.component.html',
    styleUrls: ['./updateserie.component.scss']
})
export class UpdateserieComponent implements OnInit {
    storeBaseUrl = '';

    getSerieUrl = '/admin/get-serie';

    serie: Serie = {};

    getSerieRequest: GetSerieRequest = {};

    serieForm?: FormGroup;

    updateSerieRequest: UpdateSerieRequest = {};

    updateSerieResponse: UpdateSerieResponse = {};

    updateSerieUrl = '/admin/update-serie';

    filteredProduct: Material[] = [];

    selectedBookList: BookWithSerienumber[] = [];

    addProductToSerieRequest: AddProductToSerieRequest = {};

    addProductToSerieResponse: AddProductToSerieResponse = {};

    addProductsToSerieUrl = '/admin/addproductstoserie';

    serieNumberForm?: FormGroup;

    serieNumber: number = 0;

    productIdList: number[] = [];

    storeExistingProductsURI = '/store/get-products';

    products: Material[] = [];

    filteredBooks: Material[] = [];

    deleteSerieProductUrl = '/admin/delete-serieproduct';

    deleteSerieRequest: DeleteSerieProductRequest = {};

    routerLink = '';

    serieNumberToAdd = 0;

    searchText = '';

    productsToChooseToUpdate: Material[] = [];

    productsForASerie: BookWithSerienumber[] = [];

    constructor(
        private _fb: FormBuilder,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _translateService: TranslationService,
        private _toastMessageService: ToastmessageService,
        public _router: Router
    ) {
        this.storeBaseUrl = _storeBaseUrl;
        this.createSerieForm();
        this.createSerieNumberForm();
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            const lastSegmentOfUrl = window.location.href.split('/').pop();
            const routerLink = lastSegmentOfUrl as string;
            this.routerLink = routerLink;
            this.getSerieRequest = {
                routerLink: routerLink
            };
            this._apiRequestService
                .sendRequest<GetSerieRequest, GetSerieResponse>(this.storeBaseUrl, true, this.getSerieRequest, true, this.getSerieUrl)
                .subscribe((response) => {
                    this.serie = response.serie!;
                    this._apiRequestService
                        .sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI)
                        .subscribe((response) => {
                            const products = response as GetProductsResponse;
                            this.products = products.productList as Material[];
                            this.products.forEach((product) => {
                                if (product.serieId === this.serie.id) {
                                    const index = this.productsToChooseToUpdate.indexOf(product);
                                    this.productsToChooseToUpdate.splice(index, 1);
                                    const existingBook = {
                                        book: product,
                                        serieNumber: product.serieNumber
                                    };
                                    this.productsForASerie.push(existingBook);
                                    this.selectedBookList.push(existingBook);
                                }
                                this.selectedBookList.sort((a, b) => a.serieNumber! - b.serieNumber!);
                                this.serieNumberToAdd = this.selectedBookList.length;
                            });
                            this.filteredBooks = this.products;
                            this.products.forEach((product) => {
                                if (product.serieId === this.serie.id) {
                                    this.productIdList.push(product.id!);
                                }
                            });
                        });
                });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    addProduct(product: Material) {
        this.serieNumberToAdd = this.serieNumberToAdd + 1;
        console.log(this.serieNumberToAdd);
        const bookWithSeriNumber: BookWithSerienumber = {
            book: product,
            serieNumber: this.serieNumberToAdd
        };
        const index = this.products.indexOf(product);
        this.products.splice(index, 1);
        this.selectedBookList.push(bookWithSeriNumber);
    }

    deleteFromSerie(selectedBookList: BookWithSerienumber) {
        const index = this.selectedBookList.indexOf(selectedBookList);
        this.selectedBookList.splice(index, 1);
        this.products.push(selectedBookList.book!);
    }

    increaseSerieNumber(selectedBook: BookWithSerienumber) {
        const index = this.selectedBookList.indexOf(selectedBook);
        const i = index + 1;
        this.selectedBookList.forEach((selectedBookElement) => {
            if (this.selectedBookList[i].book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = i;
            }
        });
        this.selectedBookList = this.move(index, index + 1, this.selectedBookList);
        this.selectedBookList.forEach((selectedBookElement) => {
            if (selectedBook.book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = index + 2;
            }
        });
        console.log(this.selectedBookList);
        console.log(index + 2);
    }

    decreaseSerieNumber(selectedBook: BookWithSerienumber) {
        const index = this.selectedBookList.indexOf(selectedBook);
        const i = index - 1;
        this.selectedBookList.forEach((selectedBookElement) => {
            if (this.selectedBookList[i].book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = i + 2;
            }
        });
        this.selectedBookList = this.move(index, index - 1, this.selectedBookList);
        this.selectedBookList.forEach((selectedBookElement) => {
            if (selectedBook.book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = index;
            }
        });
        console.log(index - 2);
        console.log(this.selectedBookList);
    }

    move(from: number, to: number, selectedBook: BookWithSerienumber[]): BookWithSerienumber[] {
        const newArr = [...selectedBook];

        const item = newArr.splice(from, 1)[0];
        newArr.splice(to, 0, item);

        return newArr;
    }

    createSerieForm() {
        this.serieForm = this._fb.group({
            serieName: ['', [Validators.required]]
        });
    }

    updateSerie() {
        this.serie.serieName = this.serieForm?.controls['serieName'].value as string | undefined;

        this.updateSerieRequest = {
            serie: this.serie as Serie
        };

        this._apiRequestService
            .sendRequest<UpdateSerieRequest, UpdateSerieResponse>(
                this.storeBaseUrl,
                true,
                this.updateSerieRequest,
                true,
                this.updateSerieUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage as string, MAT_ICON.success);
                this.dismissError();
                if (this.selectedBookList.length > 0) {
                    this.createProductsForSerie(response.routerLink!);
                }
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    removeSerieProduct() {
        this.deleteSerieRequest = {
            productIds: this.productIdList
        };
        this._apiRequestService
            .sendRequest<DeleteSerieProductRequest, DeleteSerieProductResponse>(
                this.storeBaseUrl,
                true,
                this.deleteSerieRequest,
                true,
                this.deleteSerieProductUrl
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage as string, MAT_ICON.success);
                this.dismissError();
            });
    }
    createProductsForSerie(routerLink: string) {
        //TODO make decision if we need to remove or not
        //this.removeSerieProduct();
        this.collectSerieNumber();
        this.selectedBookList.forEach((book) => {
            this.addProductToSerieRequest = {
                routerLink: routerLink,
                serieNumber: book.serieNumber,
                productId: book.book?.id
            };

            this._apiRequestService
                .sendRequest<AddProductToSerieRequest, AddProductToSerieResponse>(
                    this.storeBaseUrl,
                    true,
                    this.addProductToSerieRequest,
                    true,
                    this.addProductsToSerieUrl
                )
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();

                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                    this.dismissError();
                    window.location.reload();
                });
        });
    }

    createSerieNumberForm() {
        this.serieNumberForm = this._fb.group({
            serieNumber: ['', [Validators.required]]
        });
    }

    collectSerieNumber() {
        this.serieNumber = this.serieNumberForm?.controls['serieNumber'].value as number;
    }
}

<div class="contact-content">
    <h3>SOROZAT ELEMEINEK MÓDOSÍTÁSA</h3>
    <div class="drag-and-drop-container">
        <div class="search-main-container">
            <div class="search-bar">
                <div class="search-tool">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                    <input [(ngModel)]="searchText" type="search" placeholder="Szerző, cím neve" />
                </div>
            </div>
            <div class="title">
                <h3>{{ serie.serieName! }} csomag tartalma</h3>
            </div>
        </div>
        <div class="drag-and-drop">
            <div class="products-container">
                <div class="products">
                    <div *ngFor="let product of products | filter : searchText" class="product">
                        {{ product.author + ': ' + product.title }}
                        <mat-icon (click)="addProduct(product)">add_circle_outline</mat-icon>
                    </div>
                </div>
            </div>
            <div class="title-mobile">
                <h3>{{ serie.serieName }} csomag tartalma</h3>
            </div>
            <div class="products-container">
                <div class="products">
                    <div *ngFor="let product of selectedBookList; let i = index" class="product">
                        {{ product.book?.author + ': ' + product.book?.title }} {{ i + 1 }}. rész
                        <mat-icon (click)="decreaseSerieNumber(product)" *ngIf="i !== 0">keyboard_arrow_up</mat-icon>
                        <mat-icon (click)="increaseSerieNumber(product)" *ngIf="i !== selectedBookList.length - 1 || i === 0"
                            >keyboard_arrow_down</mat-icon
                        >
                        <mat-icon (click)="deleteFromSerie(product)">remove_circle_outline</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div *ngIf="_router.url === '/addserie'" class="contact-content-books">
    <div>
        <h3>
            KÖNYV HOZZÁADÁSA
            <span *ngIf="this.serieForm!.controls['serieName'].value"
                >A {{ this.serieForm!.controls['serieName'].value | uppercase }}HOZ</span
            >
        </h3>
    </div>
    <div class="contact-form">
        <div class="data">
            <mat-form-field>
                <mat-select placeholder="Válasszon könyvet" [(ngModel)]="selectedBook">
                    <mat-option *ngFor="let product of products" [value]="product">
                        {{ product.author + ': ' + product.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <form [formGroup]="serieNumberForm!" novalidate>
                <div class="field-container">
                    <div class="field">
                        <span>Sorozat sorszáma</span>
                        <div class="data">
                            <input formControlName="serieNumber" id="serieNumber" class="form-control" type="text" required />
                        </div>
                        <button (click)="addProduct()">HOZZÁADÁS</button>
                    </div>
                </div>
            </form>
        </div>
        <div>
            <h3 [ngStyle]="{ 'text-align': 'center' }" *ngIf="this.serieForm!.controls['serieName'].value">
                A <span>{{ this.serieForm!.controls['serieName'].value }}hoz tartozó könyvek</span>
            </h3>
            <h3 [ngStyle]="{ 'text-align': 'center' }" *ngIf="!this.serieForm!.controls['serieName'].value">
                A sorozathoz hozzáadott könyvek
            </h3>
            <div class="selected-book">
                <div [ngStyle]="{ padding: '8px' }" *ngFor="let selectedBoo of selectedBookList; let i = index">
                    <span>
                        <span>{{ i + 1 }}. </span>{{ selectedBoo?.book?.author! + ': ' + selectedBoo?.book?.title! }}
                        <span>{{ selectedBoo.serieNumber }}. rész</span>
                        <mat-icon (click)="deleteFromSerie(selectedBoo)">delete_forever</mat-icon>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="_router.url === '/series/' + serie.routerLink" class="contact-content-books">
    <div>
        <h3 [ngStyle]="{ 'text-align': 'center' }">A {{ this.serieForm!.controls['serieName'].value }} csomaghoz tartozó könyvek</h3>
        <div [ngStyle]="{ padding: '8px', 'text-align': 'center' }" *ngFor="let product of productsForASerie; let i = index">
            <span>
                <span>{{ i + 1 }}. </span>{{ product?.book?.author! + ': ' + product?.book?.title! }}
                <span>{{ product.serieNumber }}. rész</span>
                <mat-icon (click)="removeProductFromSerie(product?.book?.id!)">delete_forever</mat-icon>
            </span>
        </div>
    </div>
    <div [ngStyle]="{ 'text-align': 'center' }">
        <h3>
            KÖNYV HOZZÁADÁSA
            <span *ngIf="this.serieForm!.controls['serieName'].value"
                >A {{ this.serieForm!.controls['serieName'].value | uppercase }} CSOMAGHOZ</span
            >
        </h3>
    </div>

    <div class="contact-form">
        <div class="data">
            <mat-form-field>
                <mat-select placeholder="Válasszon könyvet" [(ngModel)]="selectedBook2">
                    <mat-option *ngFor="let product of productsToChooseToUpdate" [value]="product">
                        {{ product.author + ': ' + product.title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <form [formGroup]="serieNumberForm!" novalidate>
                <div class="field-container">
                    <div class="field">
                        <span>Sorozat sorszáma</span>
                        <div class="data">
                            <input formControlName="serieNumber" id="serieNumber" class="form-control" type="text" required />
                        </div>
                        <button (click)="addNewProduct()">HOZZÁADÁS</button>
                    </div>
                </div>
            </form>
        </div>

        <div class="add-product">
            <h3 [ngStyle]="{ 'text-align': 'center', display: 'flex' }" *ngIf="this.serieForm!.controls['serieName'].value">
                <span>Könyv hozzáadása a {{ this.serieForm!.controls['serieName'].value }} sorozathoz</span>
            </h3>
            <h3 [ngStyle]="{ 'text-align': 'center' }" *ngIf="!this.serieForm!.controls['serieName'].value">
                A sorozathoz hozzáadott könyvek
            </h3>
            <div class="selected-book">
                <div [ngStyle]="{ padding: '8px' }" *ngFor="let existingBook of existingProducts; let i = index">
                    <span>
                        <span>{{ i + 1 }}. </span>{{ existingBook?.book?.author! + ': ' + existingBook?.book?.title! }}
                        <span>{{ existingBook.serieNumber }}. rész</span>
                        <mat-icon (click)="deleteFromExistingSerie(existingBook)">delete_forever</mat-icon>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/packages">arrow_back</mat-icon>
    </div>
    <div>
        <h3>ÚJ CSOMAG HOZZÁADÁSA</h3>
    </div>
    <div class="contact-form">
        <form [formGroup]="packageForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <span>Csomag neve</span>
                    <div class="data">
                        <input
                            [(ngModel)]="package.packageName"
                            formControlName="packageName"
                            id="packageName"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Borítókép (.png, maximum méret: 5MB)</span>
                    <div class="data">
                        <div>
                            <button [ngStyle]="{ background: 'lightgrey' }" onclick="document.getElementById('fileCover').click()">
                                Borítókép kiválasztása
                            </button>
                            <span *ngIf="addCoverFile">{{ fileCover?.name }}</span>
                            <span *ngIf="!addCoverFile">Nincs borítókép kiválasztva</span>
                            <mat-icon (click)="clearSelectedCoverFile()">delete_forever</mat-icon>
                            <input
                                [ngStyle]="{ display: 'none' }"
                                id="fileCover"
                                #fileCoverInput
                                class="form-control"
                                type="file"
                                (change)="onChange($event)"
                            />
                        </div>
                        <!-- <button (click)="uploadFile(package)" class="btn btn-success">Borítókép feltöltése</button>
                        <span *ngIf="addCoverFile" [ngStyle]="{ color: 'red', 'font-size': '12px' }"
                            >Kérem töltse fel a fájlt a "Borítókép feltöltése" gomb megnyomásával!</span
                        > -->
                    </div>
                </div>
            </div>
        </form>
    </div>
    <h3>KÖNYVEK HOZZÁADÁSA</h3>
    <div class="drag-and-drop-container">
        <div class="search-main-container">
            <div class="search-bar">
                <div class="search-tool">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                    <input [(ngModel)]="searchText" type="search" placeholder="Szerző, cím neve" />
                </div>
            </div>
            <div class="title">
                <h3 *ngIf="!package.packageName">Új csomaghoz tartozó könyvek</h3>
                <h3 *ngIf="package.packageName">{{ package.packageName }} csomaghoz tartozó könyvek</h3>
            </div>
        </div>
        <div class="drag-and-drop">
            <div class="products-container">
                <div class="products">
                    <div *ngFor="let product of products | filter : searchText" class="product">
                        {{ product.author + ': ' + product.title }}
                        <mat-icon (click)="addProductToPackage(product)">add_circle_outline</mat-icon>
                    </div>
                </div>
            </div>
            <div class="title-mobile">
                <h3 *ngIf="!package.packageName">Új csomaghoz tartozó könyvek</h3>
                <h3 *ngIf="package.packageName">{{ package.packageName }} csomaghoz tartozó könyvek</h3>
            </div>
            <div class="products-container">
                <div class="products">
                    <div *ngFor="let product of packageProductList" class="product">
                        {{ product.author + ': ' + product.title }}
                        <mat-icon (click)="deleteFromPackageProductList(product)">remove_circle_outline</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sum">
        <div class="sum-up">
            <h3>Összesen</h3>
            <h3>{{ sum | currency : 'Ft' : 'symbol' : '1.0-0' }}</h3>
        </div>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="addPackage(package)"
                    [ngStyle]="{ background: '#e8fcfd', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!package.packageName"
                >
                    CSOMAG MENTÉSE
                </button>
            </div>
        </div>
    </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService,HttpError } from '@prekog/pw-common';
import { GetProductsResponse } from './getproductsresponse';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Material } from './material';


@Component({
    selector: 'pw-existingproducts',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
    storeBaseUrl = '';

    storeExistingProductsURI = '/admin/get-products';

    products: Material[] = [];

    filteredBooks: Material[] = [];

    searchText = '';

    placeHolder = 'Szerző, cím';

    constructor(
        private _router:Router,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _toastMessageService: ToastmessageService,
        private _translateService: TranslationService
    ) {
        this.storeBaseUrl = _storeBaseUrl;

    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        else if(sessionStorage.getItem('token')) {
        this._apiRequestService.sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(() => {
                    const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! '+httpError.errorMessage), MAT_ICON.error);
                        this.dismissError();


                    this._router.navigateByUrl('/');
                });
            })
        )
        .subscribe((response) => {
            const products = response as GetProductsResponse;
            this.products = products.productList as Material[];
            this.filteredBooks = this.products;
        });
    }
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }


    }

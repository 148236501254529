import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService, MatdialogService } from '@prekog/pw-common';
import { Package } from './package';
import { GetPackagesResponse } from './getpackagesresponse';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { RemovepackagepopupComponent } from './removepackagepopup/removepackagepopup.component';

@Component({
    selector: 'pw-existingpackages',
    templateUrl: './packages.component.html',
    styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

    storeBaseUrl = '';

    getPackagesUrl = '/admin/getpackages';

    packages: Package[] = [];

    filteredPackages: Package[] = [];

    searchText = '';

    placeHolder='Csomag neve';

    constructor(
        private _apiRequestService: ApiRequestService,
        private _router: Router,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _translateService: TranslationService,
        private _toastMessageService: ToastmessageService,
        private _matDialogService: MatdialogService

    ) {
        this.storeBaseUrl = _storeBaseUrl;

    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        } else {
            this._apiRequestService.sendRequest<{}, GetPackagesResponse>(this.storeBaseUrl, true, {}, true, this.getPackagesUrl)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage), MAT_ICON.error);
                            this.dismissError();


                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    this.packages = response.packageList!;
                    this.filteredPackages = this.packages;
                });
        }
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    openDeletePackagePopup(packageObject: Package) {
        this._matDialogService.openPopupWithData(packageObject, RemovepackagepopupComponent);
    }

}
